import { useToast } from '@chakra-ui/react';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { supabase } from '../Auth/supabaseClient'; // Ensure this is the correct path to your Supabase client instance
import { setBody, setCss, setHead, setJs, setLoading } from '../store/editorSlice';
import { MURKUP_REQUEST } from './Utilities/constants/murkupRequestConstant';
import { REQUEST_TIMEOUT } from './Utilities/constants/timeoutConstant';
import { getDefaultBody, getDefaultCss, getDefaultHead } from './Utilities/getDefaultData';

const SiteContext = createContext();

export const SiteProvider = ({ children, siteId, pageName }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [versions, setVersions] = useState([]);
  const [currentVersion, setCurrentVersion] = useState('');
  const [currentUUID, setCurrentUUID] = useState('');
  const [currentPageVersionsId, setCurrentPageVersionsId] = useState('');
  const toast = useToast();
  const [messages, setMessages] = useState([]);
  const [siteName, setSiteName] = useState('');
  const [livePreviewContent, setLivePreviewContent] = useState('');
  const [pageId, setPageId] = useState(null);
  const [isChangeHot, setHotChange] = useState(false);

  const dispatch = useDispatch();
  const timeoutData = {};
  
  /**
   * Clears the previous timeout and cancels the promise reject if it exists.
   * @param {string} type - The type of request.
   */
  function clearPreviousTimeout(type) {
    if (!timeoutData[type]) return;

    clearTimeout(timeoutData[type]);
    delete timeoutData[type];
  }
  
  /**
    * @property {string} js - js loaded from editor
    * @property {string} css - css loaded from editor
    * @property {string} body - body loaded from editor
    * @property {string} head - head loaded from editor
  */
  function setMarkupSeparately({ head = null, body = null, css = null, js = null }) {
    dispatch(setHead(head || getDefaultHead()));
    dispatch(setBody(body || getDefaultBody()));
    dispatch(setCss(css || getDefaultCss()));
    dispatch(setJs(js));
    setLivePreviewContent(true);
  }

  /**
   * Executes an asynchronous function with a timeout and cancels the previous request if a new one is made within the timeout period.
   * @param {Function} asyncFunction - The asynchronous function to be executed.
   * @param {number} timeout - The timeout in milliseconds.
   * @param {string} type - The type of request.
   * @returns {Function} A function that accepts parameters for the asyncFunction and returns a promise that resolves with the result of the latest asyncFunction call.
   */
  function debounceRequest(asyncFunction, timeout, type) {
    return () => {
      clearPreviousTimeout(type);
  
      return new Promise((resolve) => {
        timeoutData[type] = setTimeout(async () => {
          const result = await asyncFunction();
          resolve(result);
        }, timeout);
      });
    };
  }

  function setcurrentPageVersionsId(data) {
    setCurrentPageVersionsId(data);
  }

  // Fetch site name
  useEffect(() => {
    if (!siteId) return;

    const fetchSiteName = async () => {
      const { data, error } = await supabase
        .from('site')
        .select('name')
        .eq('id', siteId)
        .single();

      if (error) {
        console.error('Error fetching site name:', error);
        return;
      }

      setSiteName(data.name);
    };

    fetchSiteName();
  }, [siteId, toast]);

  // Fetch pageId based on pageName (case-insensitive) or default to page with index = true
  useEffect(() => {
    if (!siteId) return;

    const fetchPageId = async () => {
      let { data, error } = await supabase
        .from('page')
        .select('id')
        .eq('site_id', siteId)
        .ilike('name', pageName || '')
        .single();

      if (error || !data) {
        console.error('Error fetching page ID or page not found:', error);

        // If no pageName is provided or page not found, default to page with index = true
        const { data: defaultPage, error: defaultError } = await supabase
          .from('page')
          .select('id')
          .eq('site_id', siteId)
          .eq('index', true)
          .single();

        if (defaultError || !defaultPage) {
          console.error('Error fetching default page ID:', defaultError);
          return;
        }

        setPageId(defaultPage.id);
      } else {
        setPageId(data.id);
      }
    };

    fetchPageId();
  }, [siteId, pageName]);

  // Fetch content for the current version
  useEffect(() => {
    if (currentPageVersionsId) {
      fetchContentForVersion(currentPageVersionsId)
    }// eslint-disable-next-line
  }, [currentPageVersionsId]);

  // Define fetchVersions using useCallback to ensure it only changes when siteId or pageId changes
  const fetchVersions = useCallback(async () => {
    if (!siteId || !pageId) return;
    
    let { data: versions, error } = await supabase
    .from('page_versions')
    .select(`html, ${MURKUP_REQUEST}, id, version, change_description`)
    .eq('site_id', siteId)
    .eq('page_id', pageId)
    .order('version', { ascending: false });
    
    setMarkupSeparately(versions ? versions[0] : {});
    
    if (error) {
      console.error('Error fetching versions', error);
      setIsGenerating(false);
      return;
    }

    const versionOptions = versions.map((v) => ({
      id: v.version,
      label: `${v.version}`,
      SiteVersionsId: v.id,
      change_description: v.change_description

    }));
    setVersions(versionOptions);

    if (versionOptions.length > 0) {
      const latestVersion = versionOptions[0].id;
      setCurrentVersion(latestVersion);
      setcurrentPageVersionsId(versionOptions[0].SiteVersionsId);
    }// eslint-disable-next-line
  }, [siteId, pageId]);

  // Effect to invoke fetchVersions when it or its dependencies change
  useEffect(() => {
    fetchVersions();
  }, [fetchVersions]);

  // Real-time updates handling for site versions
  useEffect(() => {
    const channel = supabase
      .channel('realtime:public:page_versions')
      .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'page_versions', filter: `site_id=eq.${siteId}` }, async (payload) => {
        if (payload.new.site_id === siteId && payload.new.page_id === pageId) {
          fetchVersions();
        }
      })
      .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'page_versions', filter: `site_id=eq.${siteId}`}, async (payload) => {
        if (payload.new.id !== currentPageVersionsId) return;

        setIsGenerating(!!payload.new.generating);
        setIsGeneratingImages(!!payload.new.generating_images);

      // checks if current change just made by user
      if (isChangeHot) {
        setHotChange(false);
      } else {
        fetchContentForVersion(currentPageVersionsId);
      }
    })
    .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };// eslint-disable-next-line
  }, [siteId, pageId, currentPageVersionsId, fetchVersions]);

  // Fetch content for a specific version
  async function fetchContentForVersion(SiteVersionsId) {
    try {
      const { data, error } = await supabase
        .from('page_versions')
        .select(`generating, generating_images, ${MURKUP_REQUEST}, uuid`)
        .eq('id', SiteVersionsId)
        .single();
  
      if (error) {
        console.error("Error fetching content for version:", error);
        setIsGenerating(false);
        setIsGeneratingImages(false);
        return;
      }
  
      setIsGenerating(data.generating);
      setcurrentPageVersionsId(SiteVersionsId);
      setIsGeneratingImages(data.generating_images);
      setCurrentUUID(data.uuid);
      setMarkupSeparately(data);

    } catch (error) {
      console.error("Unexpected error fetching content:", error);
      setIsGenerating(false);
      setIsGeneratingImages(false);
    }
  }

  // Perform change
  const performChange = async (action, message, elementId) => {
    try {
      if (!action) {
        const { error } = await supabase
          .from('ai_messages')
          .insert([{ message, from_user: true, site_id: siteId, version: currentPageVersionsId }]);

        if (error) throw error;
      }
      const response = await supabase.functions.invoke('perform-change', {
        body: JSON.stringify({ action, message, siteId, currentPageVersionsId, elementId, change:'partial' }),
      });

      const data = response.data;

    if (data && data.html) {
        setMarkupSeparately(data);
      } else {
        console.error('Unexpected response format:', data);
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Error',
        description: "There was an error performing the change. Please try again",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const currentPageVersionsRef = useRef('');
  useEffect(() => { console.log('currentPageVersionsId',currentPageVersionsId); currentPageVersionsRef.current = currentPageVersionsId }, [currentPageVersionsId]);
  
  /**
 * Updates a specific part of the markup in the database and shows a toast notification.
  * @param {string} PART_TYPE - The type of the part to update (e.g., 'head', 'body', 'css' and not 'js', because it already set by AI).
  * @param {string} part_data - The data to update in the specified part.
  * @returns {Promise<void>} A promise that resolves when the update is complete.
  */
const updateMarkupPartially = async (PART_TYPE, part_data) => {
  try {
    const debouncedUpdate = debounceRequest(
      () => supabase
        .from('page_versions')
        .update({ [PART_TYPE]: part_data })
        .eq('id', currentPageVersionsRef.current)
        .select(MURKUP_REQUEST),
      REQUEST_TIMEOUT,
      PART_TYPE
    );

    const { data } = await debouncedUpdate();

    if (data) {
      setLivePreviewContent(data[0][PART_TYPE]);
   
    } else {
      console.error('Unexpected response format:', data);
    }

    setHotChange(true);
  } finally {
    dispatch(setLoading(false));
  }
};

  // Handle send messages
  const handleSendMessage = async (message, version) => {
    try {
      const { error } = await supabase
        .from('ai_messages')
        .insert([{ message, from_user: true, site_id: siteId, version }]);

      if (error) throw error;

      if (message === "Generate new version") {
        setIsGenerating(true);
        
        const response = await supabase.functions.invoke('new-version', {
          body: JSON.stringify({ message, siteId }),
        });

        const data = response.data;
        if (data && data.html) {
          setMarkupSeparately(data);
        } else {
          console.error('Unexpected response format:', data);
        }
        setIsGenerating(false);
      } else {
        async function fetchData() {
          let attempts = 0;
          const maxAttempts = 3;
          const retryDelay = 2000;

          while (attempts < maxAttempts) {
            try {
              const { data: sessionData, error: sessionError } = await supabase.auth.getSession();

              if (sessionError || !sessionData.session) {
                throw new Error("Failed to get session or no active session found");
              }

              const jwt = sessionData.session.access_token;
              const response = await fetch('https://rbfdagomafltuzfmfmgk.supabase.co/functions/v1/change-message', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${jwt}`
                },
                body: JSON.stringify({ message, siteId, currentPageVersionsId }),
              });

              if (!response.body) {
                console.log('No response body');
                return;
              }

              const reader = response.body.getReader();
              const decoder = new TextDecoder();
              let currentMessageId = Date.now();

              while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                const newText = decoder.decode(value, { stream: true });
                setMessages(prevMessages => {
                  const existingIndex = prevMessages.findIndex(msg => msg.id === currentMessageId);
                  if (existingIndex >= 0) {
                    return prevMessages.map((msg, index) =>
                      index === existingIndex ? { ...msg, message: msg.message + newText } : msg);
                  } else {
                    return [...prevMessages, { id: currentMessageId, message: newText, sender: 'Assistant' }];
                  }
                });
              }
              return;
            } catch (error) {
              console.error('Attempt ' + (attempts + 1) + ' failed: ', error.message);
              attempts++;
              if (attempts < maxAttempts) {
                console.log(`Retrying in ${retryDelay / 1000} seconds...`);
                await new Promise(resolve => setTimeout(resolve, retryDelay));
              } else {
                console.error('All attempts failed.');
                throw new Error('Failed to fetch after ' + maxAttempts + ' attempts');
              }
            }
          }
        }
        fetchData();
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Error',
        description: "There was an error generating the site. Please try again",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    setIsGenerating(false);
  };

  return (
    <SiteContext.Provider value={{
      siteId,
      pageId,
      livePreviewContent,
      setLivePreviewContent,
      isGenerating,
      isGeneratingImages,
      versions,
      currentPageVersionsId,
      currentVersion,
      updateMarkupPartially,
      performChange,
      siteName,
      setcurrentPageVersionsId,
      setCurrentVersion,
      currentUUID,
      messages,
      setMessages,
      fetchContentForVersion,
      handleSendMessage,
    }}>
      {children}
    </SiteContext.Provider>
  );
};

export const useSite = () => useContext(SiteContext);
