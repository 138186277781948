import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  Tooltip,
  useToken,
  useToast,
} from "@chakra-ui/react";
import PreviewPage from "Site/PreviewPage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoadingStatus } from "store/selectors";
import LoadingComponent from "../Site/Utilities/components/LoadingComponent";
import { ReactComponent as ArrowBackIcon } from "../assets/svg/back-arrow.svg";
import { ReactComponent as EyeOutlineIcon } from "../assets/svg/eye-outline.svg";
import { ReactComponent as ArrowForwardIcon } from "../assets/svg/forward-arrow.svg";
import { ReactComponent as SaveChanges } from "../assets/svg/save-changes.svg";
import { triggerRedo, triggerUndo } from "../store/editorSlice";
import {
  selectIsUnsavedVersionViewing,
  selectedVersionInhistoryPage,
} from "../store/selectors";
import { useSite } from "./SiteContext"; // Adjust the path as needed
import loadingTexts from "./Utilities/constants/loadingTexts"; // Adjust the path as necessary
import { EDITING_DISABLED_ON_HISTORY } from "./Utilities/constants/messagesConstants";
import moveVersionToTop from "./Utilities/setPageVarsionAsCurrent";
import GjsEditor from "./grapesjs/GrapesEditor";

const LivePreview = () => {
  const {
    livePreviewContent,
    isGenerating,
    isGeneratingImages,
    currentUUID,
    siteId,
    pageId,
  } = useSite();
  const [currentText, setCurrentText] = useState("");
  const [opacity, setOpacity] = useState(0);
  const [blue500] = useToken("colors", ["blue.300"]); // Fetch the color value
  const isUnsavedVersionViewing = useSelector(selectIsUnsavedVersionViewing);
  const toast = useToast();

  const versionInHistoryPage = useSelector(selectedVersionInhistoryPage);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoadingStatus);
  const handlePreviewClick = () => {
    const baseUrl = window.location.origin;
    const path = `${baseUrl}/preview/${currentUUID}`;
    window.open(path, "_blank");
  };

  useEffect(() => {
    let textChangeInterval;
    if (isGenerating) {
      const updateText = () => {
        setOpacity(0);
        setTimeout(() => {
          setCurrentText(
            loadingTexts[Math.floor(Math.random() * loadingTexts.length)]
          );
          setOpacity(1);
        }, 500);
      };

      updateText();
      textChangeInterval = setInterval(updateText, 2000);
    }

    return () => clearInterval(textChangeInterval);
  }, [isGenerating]);

  return (
    <Box
      bg="white"
      p={0}
      pr={0}
      height="calc(100vh - 52px)"
      borderRadius="md"
      boxShadow="md"
      position="relative"
    >
      {isGeneratingImages && (
        <Alert
          status="info"
          position="absolute"
          bottom="20px"
          left="50%"
          transform="translateX(-50%)"
          width="auto"
          display="flex"
          alignItems="center"
          boxShadow="md"
          borderRadius="md"
        >
          <AlertIcon />
          <Flex align="center">
            <Spinner size="sm" mr={2} />
            <AlertTitle>Generating images, please wait...</AlertTitle>
          </Flex>
        </Alert>
      )}
      {isGenerating ? (
        <Flex direction="column" justify="center" align="center" height="100%">
          <Spinner size="xl" color={blue500} />
          <Text
            mt={4}
            fontSize="lg"
            color="gray.500"
            style={{ opacity, transition: "opacity 0.5s ease-in-out" }}
          >
            {currentText}
          </Text>
        </Flex>
      ) : (
        <>
          <Flex
            justify="space-between"
            align="center"
            className="buttons-menu-wrapper"
          >
            <>
              <LoadingComponent isLoading={isLoading}></LoadingComponent>
              <Text>
                {isUnsavedVersionViewing && EDITING_DISABLED_ON_HISTORY}
              </Text>
            </>

            <Flex
              justify="space-between"
              align="center"
              className="buttons-menu"
              direction={{ base: "column", sm: "row" }}
              overflow="hidden"
              variant="outline"
            >
              {" "}
              {isUnsavedVersionViewing ? (
                <Tooltip label="Restore" placement="top">
                  <Button
                    className="history-btn"
                    variant="text"
                    onClick={() => {
                      moveVersionToTop(versionInHistoryPage, siteId, pageId);
                      toast({
                        title: "Version Restored",
                        description:
                          "The selected version has been restored as a new version.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                      });
                    }}
                  >
                    <SaveChanges boxSize={6}></SaveChanges>
                  </Button>
                </Tooltip>
              ) : null}
              <Tooltip label="Undo" placement="top">
                <Button
                  className="history-btn undo-btn"
                  isDisabled={!livePreviewContent || isUnsavedVersionViewing}
                  variant="text"
                  color="black.100"
                  _disabled={{ color: "gray.500" }}
                  onClick={() => dispatch(triggerUndo())}
                >
                  <ArrowBackIcon boxSize={6} />
                </Button>
              </Tooltip>
              <Tooltip label="Redo" placement="top">
                <Button
                  className="history-btn redo-btn"
                  isDisabled={!livePreviewContent || isUnsavedVersionViewing}
                  variant="text"
                  color="black.100"
                  _disabled={{ color: "gray.500" }}
                  onClick={() => dispatch(triggerRedo())}
                >
                  <ArrowForwardIcon boxSize={6} />
                </Button>
              </Tooltip>
              <Tooltip label="Preview" placement="bottom-end">
                <Button
                  className="history-btn preview-btn"
                  isDisabled={!livePreviewContent}
                  variant="text"
                  onClick={handlePreviewClick}
                >
                  <EyeOutlineIcon boxSize={6} />
                </Button>
              </Tooltip>
            </Flex>
          </Flex>

          {livePreviewContent ? (
            <>
              <GjsEditor></GjsEditor>
              {isUnsavedVersionViewing ? (
                <PreviewPage id={currentUUID} pageId={pageId} />
              ) : (
                ""
              )}
            </>
          ) : (
            <Flex
              justify="center"
              align="center"
              className="contentLoaderWrapper"
            >
              <Spinner />
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default LivePreview;
